import { defineStore } from 'pinia'

export const useCategoryStore = defineStore('categoryStore', {
  state: () => ({
    list: [],
  }),

  actions: {
    async fetches(params: any) {
      return await useApi<any>(createUrl('/categories', {
        query: params,
      }))
    },

    async fetchAll() {
      const { data } = await useApi<any>('/categories/all')

      this.list = data.value
    },

    async lists() {
      return await useApiPublic<any>('/categories/all')
    },

    async widthIds(data: any) {
      return await useApiPublic<any>('/categories/ids', {
        params: data,
      })
    },

    async withItem() {
      return await useApi<any>('/categories/item')
    },

    async withStoreId() {
      return await useApi<any>('/categories/store_id')
    },

    async add(data: any) {
      return await $api('/categories', {
        method: 'POST',
        body: data,
      })
    },

    async update(data: any) {
      return await $api(`/categories/${data.id}`, {
        method: 'PUT',
        body: data,
      })
    },

    async delete(id: number) {
      return await $api(`/categories/${id}`, {
        method: 'DELETE',
      })
    },
  },
})
